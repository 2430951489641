import { Col, Row, Spin } from 'antd';
import React, { Component } from 'react';
import AuthStoreSelect from '../../components/AuthStoreSelect';
import moment from "moment";
import GoodHoDayAPI from '../../api/GoodHoDayAPI';
import ActivitySelect from '../../components/ActivitySelect';
import DashboardCard from '../../components/DashboardCard';
import Card from '../../components/Card';
import CustomerStageFunnelChart from '../../components/chart/CustomerStageFunnelChart';

export class CommDashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeId: null,
      brandId: null,
      activityId: null,
      startDate: moment().subtract(1, 'month').format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      loading: false,
      couponUsageStat: {},
      custBehaviorStageChartOption: null,
    }
    // this.chart = React.createRef();
    this.funnelChart = React.createRef();
  }
  refresh = async () => {
    this.setState({ loading: true });
    const query = {
      store_id: this.state.storeId,
      activity_id: this.state.activityId,
      startdate: this.state.startDate,
      enddate: this.state.endDate,
    };
    //console.log(query);
    await this.updateCouponUsageStat(query);
    // this.chart.current.refresh(query);
    this.funnelChart.current.refresh(query);
    this.setState({ loading: false });
  }
  updateCouponUsageStat = async (query) => {
    //console.log(query);
    const response = await GoodHoDayAPI.reports.dashboard.stat(query);
    const result = response.data;
    //console.log(result);
    this.setState({ couponUsageStat: result });
  }
  handleStoreChange = (value, store) => {
    console.log(store);
    this.setState({
      storeId: value,
      brandId: store.brand_id
    }, () => this.refresh())
  }
  handleActivityChange = (value) => {
    this.setState({
      activityId: value,
    }, () => this.refresh())
  }
  render() {
    return <div>
      <h1>活動儀錶板</h1>
      <AuthStoreSelect onChange={this.handleStoreChange} />
      <ActivitySelect brandid={this.state.brandId} onChange={this.handleActivityChange} />
      <Spin spinning={this.state.loading}>
        <h3>發放優惠量</h3>
        <Row gutter={10}>
          <Col xs={24} sm={8} md={8} lg={8} xl={8} style={{ marginTop: 8, marginBottom: 8, textAlign: 'center' }}>
            <DashboardCard
              title="已領取優惠券"
              img={require('../../images/icons/ticket.png')}
              color="#BA59C7"
              rate={this.state.couponUsageStat.totalRate * 100}
              count={this.state.couponUsageStat.totalCount} />
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8} style={{ marginTop: 8, marginBottom: 8, textAlign: 'center' }}>
            <DashboardCard
              img={require('../../images/icons/store.png')}
              color="#FF833E"
              title="來店碼使用率"
              rate={this.state.couponUsageStat.giveawayRate * 100}
              count={this.state.couponUsageStat.giveawayCount} />
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8} style={{ marginTop: 8, marginBottom: 8, textAlign: 'center' }}>
            <DashboardCard
              img={require('../../images/icons/paper.png')}
              color="#EC5D9E"
              title="優惠碼使用率"
              rate={this.state.couponUsageStat.discountRate * 100}
              count={this.state.couponUsageStat.discountCount} />
          </Col>
        </Row>
        <h3>顧客行為流程</h3>
        <Row gutter={[10, 10]}>
          {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card>
            <span style={{ color: '#717171' }}>活動開始日 至 今日 期間之資料</span>
              <CustomerStageBarChart ref={this.chart} query={this.props.query} />
            </Card>
          </Col> */}
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Card>
              <span style={{ color: '#717171' }}>活動開始日 至 今日 期間之資料</span>
              <CustomerStageFunnelChart ref={this.funnelChart} query={this.props.query} />
            </Card>
          </Col>
        </Row>
      </Spin>
    </div>;
  }
}

export default CommDashboardPage;
